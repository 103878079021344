import React from "react";
import Footer from "@/components/footer";
//import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import AboutOne from "@/components/about-one";
import CallToActionOne from "@/components/call-to-action-one";
import TeamCarousel from "@/components/team-carousel";
import VideoOne from "@/components/video-one";
import SubscribeForm from "@/components/subscribe-form";
import TestimonialsOneCarousel from "@/components/testimonials-carousel";
import FeatureTabOne from "@/components/feature-tab-1";
import Loadable from "@loadable/component";

import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

const HeaderOne = Loadable(() => import("@/components/header-one"));
const Layout = Loadable(() => import("@/components/layout"));

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="About Us Page">
          <HeaderOne />
          <PageBanner title="Acerca de nosotros" name="Acerca" />
          {/*<AboutOne />*/}
          {/*<TestimonialsOneCarousel />*/}
          {/*<SubscribeForm />*/}
          {/*<TeamCarousel />*/}
          {/*<VideoOne />*/}
          <FeatureTabOne />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AboutPage;
